<template>
  <!--
    Used to edit the intros and outros of a videoPostprocessing theme.
  -->
  <div class="introOutroElementEdit">
    <LoadingPlaceholder v-if="loading" />

    <template v-else>
      <template v-if="introOutro">
        <div class="row">
          <div class="col-12">
            <h4 class="m-0 mt-3">
              {{ elementName }}
            </h4>
            <hr class="m-0 mb-3">
            <label>{{ $t('name') }}</label>
            <input
              v-model="elementObject.name"
              v-focus
              :class="['form-control m-input m-input--air', { 'is-invalid': $validator.errors.has('Name') }]"
              type="text"
            >
            <span
              v-show="errors.has('Name')"
              class="badge badge-danger"
            >{{ errors.first('Name') }}</span>
          </div>
          <div class="col-12">
            <hr class="m-0 mb-3">
            <label>{{ $t('description') }}</label>
            <input
              v-model="elementObject.description"
              v-focus
              :class="['form-control m-input m-input--air', { 'is-invalid': $validator.errors.has('Description') }]"
              type="text"
            >
            <span
              v-show="errors.has('Description')"
              class="badge badge-danger"
            >{{ errors.first('Description') }}</span>
          </div>
          <div class="col-12 mt-4">
            <label>{{ $t('duration') }}</label>
            <input
              v-model="elementObject.duration"
              :class="['form-control m-input m-input--air', { 'is-invalid': $validator.errors.has('Duration') }]"
              type="number"
            >
            <span
              v-show="errors.has('Duration')"
              class="badge badge-danger"
            >{{ errors.first('Duration') }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button
              class="btn btn-sm btn-primary float-right mt-4"
              @click="update()"
            >
              <font-awesome-icon
                class="mr-1"
                icon="check"
              />
              <span>
                {{ $t('save') }}
              </span>
            </button>
            <!-- CLEARFIX -->
            <div class="clearfix" />
          </div>
        </div>
      </template>
      <template v-else>
        {{ $t('noDataAvailable') }}
      </template>
    </template>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "IntroOutroElementEdit",
  mixins: [
    errorMixin
  ],
  props: {
    introOutroId: {
      type: String,
      required: true
    },
    elementId: {
      type: Number,
      required: true
    },
    elementName: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      introOutroElementIndex: null,
      introOutro: null,
      elementObject: null
    }
  },
  created () {
    this.getIntroOutro();
  },
  methods: {
    getIntroOutro () {
      this.axios.get('/VideoPostProcessing/GetIntroOutro?introOutroId=' + this.introOutroId)
        .then((response) => {
          this.introOutro = response.data;
          if (this.introOutro && Object.keys(this.introOutro) && Object.keys(this.introOutro).length > 0) {
            for(let i = 0; i < this.introOutro[this.elementName].length; i++) {
              if ("" + this.introOutro[this.elementName][i]["id"] === "" + this.elementId) {
                this.introOutroElementIndex = i;
                this.elementObject = this.introOutro[this.elementName][i];
              }
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getIntroOutroWithLoading () {
      this.loading = true;
      this.getIntroOutro();
    },
    update () {
      this.axios.put('/VideoPostProcessing/Update' + this.elementName.charAt(0).toUpperCase() + this.elementName.slice(1, this.elementName.length - 1), this.introOutro[this.elementName][this.introOutroElementIndex])
        .then(() => {
          this.$emit("reload");
          this.error_clear();
          this.$snotify.success(this.$t('introOutroElementEditComp.updatedSuccessfully'));
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
          this.loading = false;
        });
    }
  }
}
</script>

<style>
.introOutroElementEdit .zoomWrapper > svg {
  min-width: 100%;
}
</style>
